export const SPORTS = [
  {
    id: 0,
    name_ar: 'جميع الألعاب الرياضية',
    name_en: 'All Sports',
    stadiums_count: 400,
  },
  { id: 1, name_ar: 'كرة قدم', name_en: 'Football', stadiums_count: 240 },
  { id: 2, name_ar: 'كرة السله', name_en: 'Basketball', stadiums_count: 60 },
  { id: 3, name_ar: 'بادل', name_en: 'Padel', stadiums_count: 52 },
  { id: 4, name_ar: 'كرة الطائرة', name_en: 'Volleyball', stadiums_count: 40 },
  { id: 8, name_ar: 'كرة الصالات', name_en: 'Futsal', stadiums_count: 28 },
];

export const LANGS = [
  { name: 'اللغة العربية', code: 'ar' },
  { name: 'English', code: 'en' },
];

export const COUNTRIES = [
  {
    id: 1,
    name: 'Bahrain',
    name_ar: 'البحرين',
    code: 'BH',
    notes: '',
    visible: 1,
    phone_code: '973',
    iso_code: 'bh',
  },
  {
    id: 2,
    name: 'Kuwait',
    name_ar: 'دولة الكويت',
    code: 'KW',
    notes: '',
    visible: 1,
    phone_code: '965',
    iso_code: 'kw',
  },
  {
    id: 3,
    name: 'Saudi Arabia',
    name_ar: 'المملكة العربية السعودية',
    code: 'SA',
    notes: '',
    visible: 1,
    phone_code: '966',
    iso_code: 'sa',
  },
  {
    id: 4,
    name: 'United Arab Emirates',
    name_ar: 'الامارات',
    code: 'AE',
    notes: 'Add AE +971',
    visible: 1,
    phone_code: '971',
    iso_code: 'ae',
  },
  {
    id: 5,
    name: 'Oman',
    name_ar: 'سلطنة عمان',
    code: 'OM',
    notes: 'and OM +968',
    visible: 1,
    phone_code: '968',
    iso_code: 'om',
  },
  // {
  //   id: 7,
  //   name: 'SUDAN',
  //   name_ar: 'السودان',
  //   code: 'SD',
  //   notes: '',
  //   visible: 1,
  //   phone_code: '249',
  //   iso_code: 'sd',
  // },
];

export const SERVER_URL = process.env.REACT_APP_API_URL;
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY
export const SITE_KEY = process.env.REACT_APP_SITE_KEY